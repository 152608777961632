<script setup lang="ts">
import { AsfSkipToContentProps } from '@ui/types'

const props = withDefaults(defineProps<AsfSkipToContentProps>(), {
  anchor: '#',
  focusFirst: false
})
const focusableQuery = `
      a[href],
      button:not([disabled]),
      input:not([disabled]),
      select:not([disabled]),
      textarea:not([disabled]),
      [tabindex]:not([tabindex="-1"]
    `
const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
const focusFirstFocusable = () => {
  if (props.anchor === '#') {
    window.scrollTo(0, 0)
    return
  }

  let anchor: string
  if (typeof props.anchor === 'string') {
    anchor = props.anchor
  } else if (props.anchor?.hash) {
    anchor = props.anchor.hash
  } else {
    return
  }

  const anchorElement = document.querySelector<HTMLElement>(anchor)

  if (anchorElement) {
    nextTick(() => {
      const scrollToElement = () => {
        const focusable = anchorElement.querySelector<HTMLElement>(focusableQuery)

        if (focusable && isElementInViewport(focusable)) {
          EventBus.emit('enable:highlighter')
          focusable.focus()

          document.removeEventListener('scroll', scrollToElement)
        }
      }

      document.addEventListener('scroll', scrollToElement, { passive: true })
    })
  }
}
</script>
<template>
  <a
    :href="`${anchor}`"
    class="asf-skip-content"
    rel="nofollow"
    tabindex="0"
    data-keep-focus-highlighter=""
    @click="focusFirstFocusable"
  >
    {{ $t('global.skipTo', { content: props.content }) }}
  </a>
</template>
<style lang="postcss">
@import '@components/utilities/styles/SkipToContent.css';
</style>
